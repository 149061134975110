<template>
  <div class="work-form">
    <div class="work-form-layout">
      <a-form>
        <a-form-item>
          <a-input
              size="large"
              type="text"
              placeholder="请输入您的手机号码"
              v-model="form.mobile"
          />
        </a-form-item>
        <a-form-item>
          <a-input
              size="large"
              placeholder="请输入您的订单号"
              v-model="form.order_no"
          />
        </a-form-item>
        <a-form-item>
          <a-button size="large" type="primary" html-type="submit" class="submit-button" @click="handleSubmit" :loading="loading">
            提交
          </a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
import {workFormInfo, openUserInfoApi, addWorkFormContact} from "@/api/workForm";

export default {
  data() {
    return {
      form: {
        workFormId: '',
        mobile: '',
        order_no: '',
      },
      info: [],
      weChatUserNews : [],
      formDisable:false,
      loading:false
    }
  },
  created() {
    this.form.workFormId = this.$route.query.id;
    this.uuid = this.$route.query.uuid;
    delete this.$route.query.uuid;
    this.getOpenUserInfo()

  },
  methods: {
    getOpenUserInfo() {
      let that = this;
      openUserInfoApi({
        id: that.form.workFormId,
        uuid:that.uuid
      }).then((res) => {
        if (res.data.openid === undefined) {
          // let redirectUrl = '/auth/workForm?id='+that.form.workFormId+'&target=' + encodeURIComponent(that.url);
          // that.$redirectAuth(redirectUrl);
          this.$message.error('授权异常，请重新进入！')
        }
        this.weChatUserNews = res.data;
        this.getInfo()
      });
    },
    getInfo() {
      workFormInfo({
        id: this.form.workFormId,
        union_id: this.weChatUserNews.unionid
      }).then(res => {
        this.info = res.data.info
        if (this.info.name) {
          document.title = this.info.name
        }
        if(this.info.workFormContactInfo){
          this.form.workFormId = this.form.workFormId,
          this.form.mobile = this.info.workFormContactInfo.mobile,
          this.form.order_no = this.info.workFormContactInfo.orderNo
          this.formDisable = true
        }
      })
    },
    handleSubmit() {
      this.loading = true;
      let params = {
        workFormId: this.form.workFormId,
        mobile: this.form.mobile,
        order_no: this.form.order_no,
        union_id: this.weChatUserNews.unionid,
        nickname: this.weChatUserNews.nickname,
        avatar: this.weChatUserNews.headimgurl
      }
      addWorkFormContact(params).then((res) => {
        this.workFormContactInfo = res.data
        this.loading = false
        this.$message.success('提交成功');
      }).catch((res)=>{
        this.loading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>

.work-form-layout{
  padding: 5px;
}

button.submit-button {
  padding: 0 15px;
  font-size: 16px;
  width: 100%;
}
</style>
