import {request, request_op} from "../plugins/axios";

// 获取页面标题
export function workFormInfo(params) {
    return request({
        url: '/workForm/info',
        method: 'GET',
        params
    })
}

//  新增
export function addWorkFormContact (params) {
    return request({
        url: '/workFormContact/store',
        method: 'post',
        data: params
    })
}

//获取微信用户信息
export function openUserInfoApi (params) {
    return request_op({
        url: '/openUserInfo/workForm',
        method: 'GET',
        params: params
    })
}

