import {request, request_op} from "../plugins/axios";

// 获取页面标题
export function info(params) {
    return request({
        url: 'contact/formInfo',
        method: 'GET',
        params
    })
}

//  新增
export function submit (params) {
    return request({
        url: '/contact/formSubmit',
        method: 'post',
        data: params
    })
}


