<template>
  <div class="option-page">
    <h1 style="">{{ form.name }}</h1>
    <p class="description">{{ form.description }}</p>
    <div v-for="(field, index) in form.fields" :key="index" class="option-container">
      <h2 style="display: flex;margin-left: 23px">{{ field.title }}</h2>
      <div class="grid-container">
        <div v-if="field.type !=3" v-for="(option, idx) in field.options" :key="idx"
             :class="{ 'selected-image':  isSelected(index, idx)  }" class="grid-item"
             @click="handleImageClick(index,idx)">
          <img class="rounded-image" v-if="option.imgUrl" :src="option.imgUrl" :alt="option.name"/>
          <img class="rounded-image" v-if="!option.imgUrl" src="../../static/images/img.png" :alt="option.name"/>
          <!--          <p class="image-text">{{ option.name }}</p>-->
        </div>
      </div>
      <div v-if="field.type==3" class="select-time">
        <div class="select-time-span">
          <img src="../../static/images/birthday.png" style="width: 32px;height: 32px">
          <span style="margin-right: 100px;font-weight: 550">出生日期</span>
          <span style="color: #9A9B9B" @click="selectTime(index)">{{birthday_text }}</span>
          <!--          <a-date-picker allowClear :default-value="moment('1990-01-01', 'YYYY-MM-DD')" locale={locale} @change="event => onChange(event, index)" placeholder="请选择日期"/>-->
        </div>
      </div>
    </div>
    <div class="centered-button" v-if="form">
      <button :class="btnColor" @click="submitParams">我选好了
        <a-icon type="arrow-right"/>
      </button>
    </div>
    <div  v-if="showModal" class="overlay">
      <div class="tooltip" :style="{ top: '20%', left:'5%' }">
        <div class="tooltip-header">
          <p>{{ tooltipHeaderText }}</p>
        </div>
        <div class="tooltip-content">
          <p>{{ tooltipBodyText }}</p>
        </div>
        <div class="tooltip-footer" @click="handleTipClick">
          <p >{{ tooltipFooterButtonText }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {info, submit} from "@/api/contactForm";
import moment from 'moment'
import locale from 'ant-design-vue/lib/locale-provider/zh_CN'
import 'moment/locale/zh-cn'
import {weChatSdkConfig} from "@/api/shopCode";
import 'weui';
import weui from 'weui.js';
moment.locale('zh-cn')
export default {
  data() {
    return {
      formId: '',
      unionid: '',
      form: '',
      selectedopts: {},
      selectAll: false,
      btnColor: 'button-no',
      showModal: false,
      birthday_text: '请选择您的生日>',
      tooltipHeaderText: '温馨提示',
      tooltipBodyText: '',
      tooltipFooterButtonText: '',
    };
  },
  created() {
    this.formId = this.$route.query.id;
    this.unionid = this.$route.query.unionid;
    if (!this.formId) {
      this.$error({
        centered: true,
        width: '250px',
        title: '数据走丢了~~~',
        content: '请重新进入！'
      });
      return
    }
    this.getInfo()
    this.getWarrantNews()
  },
  methods: {
    moment,
    handleTipClick(){
      this.showModal = false;
      if (this.tooltipFooterButtonText=='去看看'){
        this.jumpMini();
      }
    },
    selectTime(s){
      let arr = this.selectedopts
      let that = this
      weui.datePicker({
        start: 1900,
        defaultValue: [1990, 1, 1],
        onConfirm: function(result){
          let date = new Date(result[0].value,result[1].value-1,result[2].value)
          if (date) {
            arr[s] = [that.formatDate(date)]
            that.birthday_text= that.formatDate(date)
          } else {
            arr[s] = []
          }
          console.log(result);
          that.checkSelect()

        },
        id: 'datePicker'
      });

    },
    getInfo() {
      let that = this;
      info({
        id: that.formId,
      }).then((res) => {
        if (res.code !== 200) {
          this.$error({
            centered: true,
            width: '250px',
            title: '数据走丢了~~~',
            content: res.msg ?? '',
          });
        }
        this.form = res.data
        //todo 时间判断

      });
    },
    submitParams() {
      this.showModal = true
      this.tooltipHeaderText = '温馨提示'
      this.tooltipFooterButtonText = '好的'
      if (this.checkSelect()) {
        this.tooltipBodyText = '表单还没选完呢~'
        return
      }
      if (!this.unionid) {
        this.tooltipBodyText = '浏览展示，不支持填写~'
        return
      }
      submit({
        id: this.formId,
        unionid: this.unionid,
        params: this.selectedopts,
      }).then((res) => {
        if (res.data[0] == 1) {
          this.tooltipBodyText = '未到问卷填写时间~'
          return
        }
        if (res.data[0] == 2) {
          this.tooltipBodyText = '问卷时间已经结束啦~'
          return
        }
        if (res.data[0] == 3) {
          this.tooltipBodyText = '你已经填写过一次表单了，无需重复填写哦~'
          return
        }
        this.tooltipFooterButtonText = '去看看'
        if (this.form.integral > 0) {
          this.tooltipBodyText = '您的问卷调查已完成，积分奖励已发送，请前往查看~'
        }
        if (this.form.coupon) {
          this.tooltipBodyText = '您的问卷调查已完成，优惠券奖励已发送，请前往查看~'
        }
      });
    },
    checkSelect() {
      let res = false
      for (let i = 0; i < this.form.fields.length; i++) {
        if (this.form.fields[i].required == 1 && (((i in this.selectedopts) && this.selectedopts[i].length == 0) || !(i in this.selectedopts))) {
          res = true;
        }
      }
      if (res) {
        this.btnColor = 'button-no'
      } else {
        this.btnColor = 'button-yes'
      }
      return res;
    },
    onChange(date, index) {
      let arr = this.selectedopts
      if (date) {
        arr[index] = [date.format('YYYY-MM-DD')]
      } else {
        arr[index] = []
      }
      this.checkSelect()
    },
    handleImageClick(index, idx) {
      console.log(this.selectedopts)

      // 切换选中状态  
      if (this.isSelected(index, idx)) {
        // 如果已经选中，则取消选中  
        const selectedInCategory = this.selectedopts[index];
        if (selectedInCategory) {
          this.$set(this.selectedopts, index, this.selectedopts[index].filter(selectedImageIndex => selectedImageIndex !== idx));
        }
      } else {
        if (this.form.fields[index].type == 1 && this.selectedopts.hasOwnProperty(index) && this.selectedopts[index].length > 0) {
          this.$message.warning('该选项是单选');
          return false
        }
        // 如果未选中，则添加到选中列表中
        this.$set(this.selectedopts, index, (this.selectedopts[index] || []).concat(idx));
      }
      this.checkSelect()

    },
    isSelected(index, idx) {
      // 检查当前图片是否在当前分类中被选中
      const selectedInCategory = this.selectedopts[index];
      return selectedInCategory && selectedInCategory.includes(idx);
    },
    handleSubmit() {
      this.loading = true;
      let params = {
        workFormId: this.form.workFormId,
        mobile: this.form.mobile,
        order_no: this.form.order_no,
        union_id: this.weChatUserNews.unionid,
        nickname: this.weChatUserNews.nickname,
        avatar: this.weChatUserNews.headimgurl
      }
      addWorkFormContact(params).then((res) => {
        this.workFormContactInfo = res.data
        this.loading = false
        this.$message.success('提交成功');
      }).catch((res) => {
        this.loading = false
      })
    },
    jumpMini() {
      console.log('跳转微信小程序')
      if (typeof wx !== 'undefined') {
        wx.miniProgram.switchTab({
          url: '/pages/member' // 替换为你想要跳转的小程序页面路径
        });
      }
    },
    //获取微信config信息
    getWarrantNews() {
      var url = window.location.href.split('#')[0];
      let that = this;
      weChatSdkConfig({
        url: url,
        corpId: 1
      }).then((res) => {
        let that = this
        this.setConfigNews(res.data)
      })
    },
    setConfigNews(data) {
      wx.config({
        debug: false,
        appId: data.appId,
        timestamp: data.timestamp,
        nonceStr: data.nonceStr,
        signature: data.signature,
        jsApiList: []
      });
    },
    formatDate(date) {
      if (!date) return null
      const year = date.getFullYear()
      const month = (1 + date.getMonth()).toString().padStart(2, '0')
      const day = date.getDate().toString().padStart(2, '0')

      return `${year}-${month}-${day}`
    },
  }
};
</script>

<style scoped>
.option-page {
  /* 页面样式 */
  background-color: #f6f4f4;
}

.option-container {
  /* 选项容器样式 */
}

.grid-container {
  margin-left: 10px;
  margin-right: 25px;
  display: grid;
  position: relative; /* 设置为相对定位容器 */
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
}


.grid-item {
  /* 单个格子样式 */
  position: relative; /* 设置为相对定位容器 */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 110px; /* 设置容器宽度 */
  height: 110px; /* 设置容器高度 */
  margin: 5px;

}


.rounded-image {
  width: 100px;
  height: 100px;
  border-radius: 7px; /* 设置圆角 */
}

.image-text {
  position: absolute; /* 绝对定位，相对于最近的定位祖先元素（这里是.image-container） */
  top: 80%; /* 距离容器顶部50% */
  left: 50%; /* 距离容器左侧50% */
  transform: translate(-50%, -50%); /* 调整文本位置，使其居中 */
  color: #dad8d8; /* 设置文本颜色 */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); /* 添加文本阴影，提高可读性 */
  font-size: 15px; /* 设置字体大小 */
  text-align: center; /* 文本居中 */
}

h1 {
  padding-top: 20px;
  font-size: 25px;
  margin-left: 20px;
  margin-top: 20px;
  font-weight: bold;
}

.description {
  margin-left: 20px;
  margin-right: 20px;
  font-size: 14px;
  color: #818181;
}

h2 {
  margin-top: 25px;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
}

.selected-image {
  /* 定义选中图片容器的样式 */
  border: 2px solid #f5d795; /* 使用 Ant Design 的主色 */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* 添加阴影效果 */
}

.select-time {
  /*display: flex;*/
  /*justify-content: center; !* 水平居中 *!*/
  /*align-items: center; !* 垂直居中 *!*/
  margin-left: 20px;
  height: 100%; /* 或者你想要的任何高度 */
}

.select-time-span {
  background-color: #ffffff;
  border-radius: 10px; /* 设置圆角 */
  width: 91%;
  height: 50px;
  display: flex;
  align-items: center; /* 垂直居中 */
  display: flex;
  justify-content: space-around;
  flex-direction: row;
}

.centered-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15vh; /* 使按钮在视口中垂直居中 */
}

.button-yes {
  display: inline-block;
  padding: 10px 20px;
  font-size: 20px;
  color: white;
  background-color: #083683;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  transition-duration: 0.4s;
  border-radius: 25px; /* 设置圆角 */
  width: 70%;
  height: 60px;
}

.button-no {
  display: inline-block;
  padding: 10px 20px;
  font-size: 20px;
  color: white;
  background-color: #818181;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  transition-duration: 0.4s;
  border-radius: 25px; /* 设置圆角 */
  width: 70%;
  height: 60px;
}
.tooltip {
  position: fixed;
  display: flex;
  flex-direction: column;
  background-image: url('~@/static/images/tip.png'); /* 替换为你的图片路径 */
  background-size: cover;
  width: 350px; /* 根据需要调整 */
  height: 410px; /* 根据需要调整 */
  padding: 10px;
  color: #907351; /* 根据图片和文字颜色调整 */
  text-align: center;
  font-family: Arial, sans-serif;
  border-radius: 10px;
}

.tooltip-header {
  color: #0d0d0d;
  margin-top: 150px; /* 根据需要设置间距 */
  font-weight: 550;
  font-size: 18px;
}

.tooltip-content {
  color: #0d0d0d;
  flex-grow: 1; /* 使内容部分占据剩余空间 */
  font-weight: 550;
  margin-left: 50px;
  margin-right: 50px;
}

.tooltip-footer {
  color: white;
  display: flex;
  justify-content: center; /* 使按钮居中 */
  font-size: 18px;
}

.tooltip button {
  border: none;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  border-radius: 4px;
  cursor: pointer;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明黑色背景 */
  z-index: 999; /* 确保遮罩层在其他内容之上 */
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}
</style>